import React, { useEffect, useState } from 'react';
import { navigate } from '@reach/router';
import { isOnlineAuction } from 'src/utils/auctionTypes';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import DetailedCardWithBackground from 'components/DetailedCardWithBackground';
import GoogleMap from 'components/GoogleMap';
import ViewIcon from 'svg/ViewIcon';
import AdmitIcon from 'svg/AdmitIcon';
import BidderIcon from 'svg/BidderIcon';
import ImageBanner from 'components/ImageBanner';
import CarIcon from 'svg/CarIcon';
import ContactPhoneIcon from 'svg/ContactPhoneIcon';
import MailIcon from 'svg/MailIcon';
import PDFApplication from 'static/pdf/GCo_Bidder_Registration_Application_0719.pdf';
import HeroSection from 'components/HeroSection';
import TitleDescriptionCollapse from 'components/TitleDescriptionCollapse';
import AuctionInfo from 'components/AuctionInfo'; // leaving for reference in case needed later
import DetailsInfo from 'components/DetailsInfo';
import styles from './auction.module.scss';
import {
  getAuctionEventDatesFlat,
  getCataloguePreFilteredByAuction,
  getEventDateAverage,
  getAuctionEventDates,
  getAuctionEventStartDates,
} from 'shared/helpers/auctions';
import moment from 'moment';
import YouTubePlayer from 'features/YoutubePlayer';
import TwitterFeed from 'features/TwitterFeed';
import { generateContentfulAssetURL } from 'utils/image';
import { useWebPagesSlugs } from 'services/graphql/hooks/WebPagesSlugs';
import { SLUGS } from 'utils/webPageTypesConstants';
import Calendar from 'components/Calendar';
import UpcomingAuctions from 'features/UpcomingAuctions/UpcomingAuctions';
import AuctionFirstToKnow from 'components/AuctionFirstToKnow/AuctionFirstToKnow';
import { useStaticQueryGetAuctionCatalogue } from 'services/graphql/queries/catalogue';
import { useMutation, useQuery } from '@apollo/client';
import { useMediaQuery } from 'react-responsive';
import { SEND_TERMS_AND_CONDITION_EMAIL } from 'services/graphql/queries/vehicles-catalogue';
import FirstToKnowModal from 'features/vehicleDetails/beFirstToKnowModal/FIrstToKnowModal';
import Spinner from 'components/Spinner';
import Alert from 'components/Alert';
// import { amplitudeEvents, trackEvent } from 'utils/amplitude';
import MailSubmittedModal from 'components/mailSubmittedModal';
import { isBrowser } from 'shared/helpers/vehicles';

const Auctions = ({ auction, user, slug }) => {
  const auctionData = auction.auction;
  const GOOGLE_MAP_URL = `http://maps.google.com/maps?q=${auctionData.location?.venueLocation?.lat},${auctionData.location?.venueLocation?.lon}`;
  const isMobile = useMediaQuery({ maxWidth: 992 });
  const auctionType = auctionData?.eventAttendanceMode || '';
  const auctionEvents = getAuctionEventDatesFlat(auctionData);
  const auctionEventsStartDates = getAuctionEventStartDates(auctionData);
  const auctionEventsFullData = getAuctionEventDates(auctionData);
  const oneLineAuctionEvents = getEventDateAverage(
    auctionEventsFullData,
    auctionData.location?.address?.addressCountry !== 'GB'
  );
  const auctionYear = moment(auctionEvents[0]).format('YYYY');
  const maxStartDate = Date.parse(auctionEvents[auctionEvents.length - 1]);
  const today = Date.parse(new Date());
  const slugs = useWebPagesSlugs();
  const constructOnlineCatalogueUrl = getCataloguePreFilteredByAuction(
    auctionData,
    auctionYear,
    slugs[SLUGS.vehiclesCatalog],
    today < maxStartDate
  );
  const getAcceptablePaymentMethods = offers => {
    let paymentMethods = new Set();
    offers.forEach(offer => {
      if (offer && offer.acceptedPaymentMethod) {
        offer.acceptedPaymentMethod.forEach(paymentMethod => {
          paymentMethods.add(paymentMethod);
        });
      }
    });
    return getPaymentMethod(paymentMethods);
  };

  const getPaymentMethod = paymentMethods => {
    const cash = paymentMethods.has('Cash');
    const creditCard = paymentMethods.has('Credit Card');
    let payments = '';
    if (cash) payments += 'Cash';
    if (creditCard) {
      if (cash) payments += ' or Credit Card';
      else payments += 'Credit Card';
    }

    return payments;
  };

  const getBidderRegistration = offers => {
    const bidderRegistration = [];
    offers
      .filter(offer => {
        return (
          offer.category === 'Bidder Registration' &&
          offer.eligibleQuantity === 1 &&
          offer.eligibleCustomerType.filter(customerType => {
            return customerType.title === 'Standard';
          }).length > 0
        );
      })
      .forEach(offer => {
        bidderRegistration.push({
          title: `${offer.price}
            ${offer.priceCurrency} includes 
            ${offer.includesObject?.includes('Catalogue') ? 'a catalogue' : ''},
            ${
              offer.includesObject?.includes('Single Admission')
                ? 'admission for one to the viewing and auction'
                : offer.includesObject?.includes('Double Admission')
                ? 'admission for two to the viewing and auction'
                : ''
            }
            ${
              offer.includesObject?.includes('Reserved Seating')
                ? offer.includesObject?.includes('Single Admission')
                  ? 'with one reserved seats, subject to availability'
                  : offer.includesObject?.includes('Double Admission')
                  ? 'with two reserved seats, subject to availability'
                  : ''
                : ''
            }
          `,
        });
      });
    return bidderRegistration;
  };

  // online auctions registration opens before the first subevent by week
  const getRegistrationOpenDate = auctionEvents => {
    const registerOpenDate = (
      moment(auctionEvents?.length > 0 && auctionEvents[0]) || moment()
    )
      .subtract(7, 'days')
      .format('MMMM Do');

    return registerOpenDate;
  };

  const constructAuctionInfo = auctionData => {
    const info = [];

    // Viewing Section
    if (
      auctionData.subEvents &&
      auctionData.subEvents.filter(
        event => event.sys.contentType.sys.id === 'subEventViewing'
      ).length > 0
    ) {
      const subEventViewing = [];
      auctionData.subEvents
        .filter(event => event.sys.contentType.sys.id === 'subEventViewing')
        .forEach(event => {
          subEventViewing.push({
            title: new Date(event.startDate).toLocaleString('en-US', {
              weekday: 'long',
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            }),
            time: `
              ${new Date(event.startDate).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
              -
              ${new Date(event.endDate).toLocaleString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
              })}
              `,
          });
        });

      info.push({
        icon: <ViewIcon />,
        title: 'Viewing',
        info: subEventViewing,
      });
    }

    // Admittance Section
    if (
      auctionData.offers &&
      auctionData.offers.filter(
        offer =>
          offer.category === 'Admittance' || offer.category === 'Catalogue'
      ).length > 0
    ) {
      const Admittance = [];

      auctionData.offers
        .filter(offer => offer.category === 'Admittance')
        .forEach(offer => {
          Admittance.push({
            title: `Admittance - ${offer.price} ${offer.priceCurrency}, admits one to all events`,
          });
        });

      auctionData.offers
        .filter(offer => offer.category === 'Catalogue')
        .forEach(offer => {
          Admittance.push({
            title: `Catalogue - ${offer.price} ${offer.priceCurrency}, admits two to all events`,
          });
        });

      Admittance.push({
        title: getAcceptablePaymentMethods(auctionData.offers),
      });

      if (
        auctionData.offers.filter(
          offer => offer.category === 'Child Admittance'
        ).length > 0
      ) {
        Admittance.push({ title: 'Children under 12 free.' });
      }

      info.push({
        icon: <AdmitIcon />,
        title: 'Admittance',
        info: Admittance,
      });
    }

    // Bidder Registration
    if (
      auctionData.offers &&
      auctionData.offers.filter(
        offer => offer.category === 'Bidder Registration'
      ).length > 0
    ) {
      info.push({
        icon: <BidderIcon />,
        title: 'Bidder Registration',
        info: getBidderRegistration(auctionData.offers),
      });
    }

    // Bidder Registration online auctions
    if (isOnlineAuction(auctionType)) {
      info.push({
        icon: <AdmitIcon />,
        title: 'Bidder Registration',
        info: [
          {
            title: `Online bidder registration will open on ${getRegistrationOpenDate(
              auctionEvents
            )} and will be available through our website and mobile apps. The full Conditions of Sale will be posted at that time.`,
          },
        ],
      });
    }

    // Buyer's Premium
    if (
      auctionData.offers &&
      auctionData.offers.filter(offer => 'sys' in offer).length > 0
    ) {
      const buyersPremium = [];
      auctionData.offers
        .filter(offer => 'sys' in offer)
        .filter(
          offer =>
            offer.sys.contentType.sys.contentful_id === 'priceSpecification'
        )
        .forEach(offer => {
          if (offer.description) {
            buyersPremium.push({ title: offer.description.description });
          }
        });

      info.push({
        title: 'Buyer’s Premium:',
        icon: <CarIcon />,
        info: buyersPremium,
      });
    }

    // On Site Contact
    if (auctionData.organizer && auctionData.organizer.contactPoint) {
      info.push({
        icon: <ContactPhoneIcon />,
        title: isOnlineAuction(auctionType)
          ? 'Contact Information'
          : 'On Site Contact',
        info: [
          {
            type: 'tel',
            title: auctionData.organizer.contactPoint.telephone
              ? `Telephone: `
              : '',
            tel: auctionData.organizer.contactPoint.telephone || '',
          },
          {
            type: 'tel',
            title: auctionData.organizer.contactPoint.faxNumber ? `Fax: ` : '',
            tel: auctionData.organizer.contactPoint.faxNumber || '',
          },
          {
            title: auctionData.organizer.contactPoint.email
              ? `Email: ${auctionData.organizer.contactPoint.email}`
              : '',
          },
          {
            title: isOnlineAuction(auctionType) ? (
              <div className="mt-4">
                <p>Gooding &amp; Company</p>
                <p className="p-0">1517 20th Street</p>
                <p>Santa Monica, CA 90404</p>
              </div>
            ) : (
              ''
            ),
          },
        ],
      });
    }

    // Mailing Address
    if (
      auctionData.location &&
      auctionData.organizer &&
      auctionData.location.mailingAddress
    ) {
      info.push({
        icon: <MailIcon />,
        title: 'Mailing Address',
        info: [
          {
            title: auctionData.organizer.name,
          },
          {
            title: auctionData.location.mailingAddress.streetAddress,
          },
          {
            title: `${auctionData.location.mailingAddress.addressLocality} ${auctionData.location.mailingAddress.addressRegion} ${auctionData.location.mailingAddress.postalCode}`,
          },
        ],
      });
    }

    return info;
  };

  const getShowVehiclesButton = (auctionData, title) => {
    let button = null;
    if (auctionData.lot?.length > 0) {
      auctionData.lot.every(lot => {
        if (lot.item?.id) {
          button = {
            title: title,
            path: constructOnlineCatalogueUrl,
          };
          return false;
        } else return true;
      });
    }
    return button;
  };

  const showVehiclesButton = getShowVehiclesButton(auctionData, '');

  const conditionOfSaleButton = auctionData?.conditionOfSale?.file?.url
    ? [
        {
          title: 'Conditions of Sale',
          onClick: () => window.open(auctionData.conditionOfSale.file.url),
        },
      ]
    : [];

  const seoImage = {
    url: generateContentfulAssetURL(auction.featureImage?.file?.url),
    width: auction.featureImage?.file?.details?.image?.width,
    height: auction.featureImage?.file?.details?.image?.height,
  };

  const hasTime = dateString => {
    return dateString?.substring(11).startsWith('00:00');
  };
  const [openModal, setOpenModal] = useState(false);
  const toggleModal = () => {
    setOpenModal(!openModal);
  };
  const [isForm, setIsForm] = useState(true);
  const [
    sendEmail,
    { error: sendEmailError, data: emailData, loading: isSendingEmail },
  ] = useMutation(SEND_TERMS_AND_CONDITION_EMAIL);
  const toggleIsForm = () => {
    setIsForm(!isForm);
  };
  const submit = async values => {
    if (window.dataLayer)
      window.dataLayer.push({
        event: 'contact-us-submit-form',
      });
    const fields = {
      ...values,
      title: `Be the First to Know Request - ${auction?.name}`,
    };
    delete fields.confirmEmail;
    await sendEmail({
      variables: {
        fields,
      },
    }).then(res => {
      setIsForm(false);
      if (isMobile) {
        setTimeout(() => {
          toggleModal();
        }, 5000);
      }
    });
  };
  // trackEvent(amplitudeEvents.VIEW_AUCTION_PAGE, {
  //   email_address: user?.email,
  //   auction_name: auctionData.name,
  // });
  return (
    <Layout
      withOutBodyPadding={true}
      transparentHeader={true}
      paddingTop={false}
    >
      {isSendingEmail && <Spinner />}
      {sendEmailError && <Alert color="danger" msg={sendEmailError.message} />}
      <FirstToKnowModal
        isAuctionDetails={true}
        auction={auction?.title}
        isOpen={openModal}
        toggle={toggleModal}
        isForm={isForm}
        submit={submit}
      />
      <SEO
        title={auctionData.name}
        metaTitle={
          auctionData?.metaTitle ?? `${auctionData.name}| Gooding & Company`
        }
        description={
          auctionData?.metaDescription?.childMarkdownRemark?.rawMarkdownBody ??
          `Join the market leading global auction house for our ${auctionData?.name}.View the online catalogue and register to bid to stay up to date with the latest news.`
        }
        image={seoImage}
        link={`${isBrowser() && window.location.origin}/auction/${slug}`}
      />
      <HeroSection
        key={`auctionsHeroSection`}
        image={auction.featureImage}
        title={auctionData.name}
        titleWithoutMargin={true}
        description={`<p class="titleLg">${oneLineAuctionEvents}</p>`}
        displayDescriptionPlaceholder={true}
        callToActions={auctionData?.webpage__auction?.[0]?.ctaList}
        isAuctionDetails={true}
        isMobile={isMobile}
        toggleModal={toggleModal}
      />
      <div className={styles.firstToKnow}>
        <AuctionFirstToKnow submit={submit} auction={auction?.auction?.name} />

        {!isForm && <MailSubmittedModal />}
      </div>
      {auctionData.activeAuction && !isOnlineAuction(auctionType) ? (
        <>
          <h1 className="streams-header">LIVE STREAM</h1>
          <div className="stream-grid">
            <div className="column-2-3">
              <YouTubePlayer
                url={`https://www.youtube.com/embed/${auctionData.organizer.youTubeChannelId}`}
                player={'iFrame'}
              />
            </div>
            <div className="column-1-3">
              <TwitterFeed
                twitterHandle={auctionData.organizer.twitterHandle}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
      <TitleDescriptionCollapse
        backgroundColor="#fff"
        title={'The Auction'}
        description={auctionData.description.childMarkdownRemark.html}
        isMarkdown={true}
        dates={
          auctionData?.webpage__auction?.[0]?.auctionStartDateText?.map(
            date => {
              return {
                title: date,
                bold: true,
              };
            }
          ) || []
        }
        locations={[
          { title: auctionData.location?.description },
          { title: auctionData.location?.address?.streetAddress || '' },
          {
            title: `${auctionData.location?.address?.addressLocality || ''} 
                      ${auctionData.location?.address?.addressRegion || ''} 
                      ${auctionData.location?.address?.addressCountry || ''}`,
          },
        ]}
        topButtons={
          isOnlineAuction(auctionType)
            ? [
                {
                  title: 'Online Bidding FAQ',
                  onClick: () => navigate(`/${slugs[SLUGS.FAQ]}`),
                },
                ...conditionOfSaleButton,
              ]
            : [...conditionOfSaleButton]
        }
      >
        <Calendar auctionData={auctionData} />
      </TitleDescriptionCollapse>
      <div className={styles.infoWrapper}>
        {/* <AuctionInfo
          className={isOnlineAuction(auctionType) ? 'onlineAuction' : ''}
          auctionInfo={constructAuctionInfo(auctionData)}
        /> */}
        {auctionData.webpage__auction?.[0].detailsList?.length && (
          <>
            {!isOnlineAuction(auctionType) &&
              auctionData?.location?.venueLocation?.lat &&
              auctionData?.location?.venueLocation?.lon && (
                <div className={styles.mapContainer}>
                  <GoogleMap
                    lat={auctionData.location.venueLocation.lat}
                    lng={auctionData.location.venueLocation.lon}
                    buttons={[
                      {
                        title: 'Get Directions',
                        onClick: () => window.open(GOOGLE_MAP_URL),
                      },
                    ]}
                  />
                </div>
              )}
            <DetailsInfo
              info={auctionData.webpage__auction?.[0].detailsList}
              cutAfter={250}
              maxHeight={115}
            />
          </>
        )}
      </div>
      <ImageBanner
        fluidImage={auction.vehicleImage?.fluid}
        title={`${auctionData.name} Vehicles`}
        buttons={
          showVehiclesButton ? [getShowVehiclesButton(auctionData, 'View')] : []
        }
      />
      <DetailedCardWithBackground
        position="center"
        cards={[
          {
            title: 'Now Inviting Consignments',
            buttons: [
              {
                title: 'Submit Your Vehicle Online',
                onClick: () => navigate(`/${slugs[SLUGS.consignment]}`),
              },
            ],
            footer: {
              title: 'Or, Download PDF Form',
              onClick: () => window.open(PDFApplication),
            },
          },
        ]}
      />
      <div className={styles.upcomingSection}>
        <UpcomingAuctions
          details={true}
          fullWidth={true}
          auctionData={auction}
        />
      </div>
    </Layout>
  );
};

export default Auctions;
